import { Helmet } from 'react-helmet';

const Instrucoes = () => {
    return (
        <>
            <Helmet>
                <title>Instalação - Fluxo MTA</title>
                <meta name="description" content="This is the home page" />
            </Helmet>
            <div className="relative max-w-4xl mx-auto my-8">
                <div className="flex items-center justify-center">
                    <div className="w-full mx-auto">
                        <div className="p-2 rounded-md mt-4 shadow-md">
                            <iframe
                                src="https://player.vimeo.com/video/1011167771"
                                className="w-full aspect-video"
                                frameBorder="0"
                                allow="autoplay; fullscreen"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Instrucoes;
