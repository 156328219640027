import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LoginPage from './Pages/Auth/LoginPage';
import RegisterPage from './Pages/Auth/RegisterPage';
import DashboardPage from './Pages/DashboardPage';
import ProtectedRoute from './Components/ProtectedRoute';
import HomePageIndex from './Pages/Index';
import Header from './Components/Menu/Menu';
import EditAccountPage from './Pages/Dashboard/EditAccountPage';
import EditAddress from './Pages/Dashboard/EditAddress';
import TickerPage from './Pages/Painel/TickerPage';
import MaioresAltas from './Pages/Painel/MaioresAltas';
import MaioresBaixas from './Pages/Painel/MaioresBaixas';
import MyAccount from './Pages/Dashboard/MyAccount';
import CompareTickers from './Pages/Painel/CompareTickers';
import AssinePage from './Pages/Store/AssinePage'; // Caso essa página exista e não precise ser protegida
import Instrucoes from './Pages/Dashboard/Instrucoes';

function AppContent() {
  const location = useLocation();
  const hideHeaderPaths = ['/login', '/cadastro', '/assine'];

  const contentClassName = hideHeaderPaths.includes(location.pathname) ? '' : 'bg-gray-100 px-4 pt-24 lg:pt-36 lg:px-0 container mx-auto';

  return (
    <>
      {!hideHeaderPaths.includes(location.pathname) && <Header />}
      <div className={`${contentClassName}`}>
        <Routes>
          {/* Protegendo todas as rotas para que apenas usuários com user_lvl_02 ou superior possam acessá-las */}
          <Route
            path="/"
            element={
              <ProtectedRoute requiredLevel="user_lvl_02">
                <HomePageIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ticker/:tickerSymbol"
            element={
              <ProtectedRoute requiredLevel="user_lvl_02">
                <TickerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/maiores-altas"
            element={
              <ProtectedRoute requiredLevel="user_lvl_02">
                <MaioresAltas />
              </ProtectedRoute>
            }
          />
          <Route
            path="/maiores-baixas"
            element={
              <ProtectedRoute requiredLevel="user_lvl_02">
                <MaioresBaixas />
              </ProtectedRoute>
            }
          />
          <Route
            path="/comparador"
            element={
              <ProtectedRoute requiredLevel="user_lvl_02">
                <CompareTickers />
              </ProtectedRoute>
            }
          />

          {/* Protegendo o Dashboard com um nível mínimo de acesso user_lvl_02 */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute requiredLevel="user_lvl_02">
                <DashboardPage />
              </ProtectedRoute>
            }
          />

          {/* Página de Login acessível para todos */}
          <Route
            path="/login"
            element={<LoginPage />}
          />

          {/* Página de Cadastro acessível para todos */}
          <Route
            path="/cadastro"
            element={<RegisterPage />}
          />

          {/* Página "Assine" acessível para todos */}
          <Route
            path="/assine"
            element={<AssinePage />}
          />

          {/* Atualização da rota para incluir o parâmetro opcional :tab */}
          <Route
            path="/minha-conta/:tab?"
            element={
              <ProtectedRoute requiredLevel="user_lvl_01">
                <MyAccount />
              </ProtectedRoute>
            }
          />

          {/* Protegendo rotas de edição de conta e endereço para user_lvl_01 */}
          <Route
            path="/dados"
            element={
              <ProtectedRoute requiredLevel="user_lvl_01">
                <EditAccountPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/endereco"
            element={
              <ProtectedRoute requiredLevel="user_lvl_01">
                <EditAddress />
              </ProtectedRoute>
            }
          />
          <Route
            path="/instalacao"
            element={
              <Instrucoes />
            }
          />
        </Routes>
      </div>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
