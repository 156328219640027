import React, { useContext } from 'react';
import { Navigate, Link } from 'react-router-dom';
import Login from '../../Components/Auth/Login';
import Logo from '../../assets/images/fluxomta.webp';
import { UserContext } from '../../context/UserContext';
import Spinner from '../../Components/Spinner';

const LoginPage = () => {
    const { user, setUser, loading } = useContext(UserContext);

    if (loading) {
        return <div><Spinner /></div>;
    }

    if (user) {
        return <Navigate to="/" />;
    }

    return (
        <div className="bg-primary-500">
            <div className="flex justify-center h-screen">
                <div className="hidden bg-cover lg:block lg:w-2/3 ">
                    <div className="flex items-center h-full px-20 bg-primary-900">
                        <div>
                            <img className="w-48 " src={Logo} alt="Logo" />
                        </div>
                    </div>
                </div>

                <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6 ">
                    <div className="flex-1">
                        <div className="text-center">
                            <div className='text-lg text-white'>
                                <img className="w-auto mx-auto h-7 sm:h-8" src={Logo} alt="Logo" />
                                <p className="mt-3 text-gray-500 ">Bem vindo de Volta!</p>
                            </div>
                        </div>

                        <div className="mt-8">
                            <Login setUser={setUser} /> {/* Passa setUser para o Login */}
                            <p className="mt-6 text-sm text-center text-gray-400">Não consegue acessar? <Link to="https://assine.fluxomta.com/minha-conta/recuperar-senha" className="font-semibold underline text-secondary-500">recuperar senha.</Link>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
