import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Variável para habilitar ou desabilitar os dados mocados
  const useMockData = false; // Troque para `false` para usar a API real

  // Dados mocados para teste
  const mockUser = {
    "avatar_url": "https://secure.gravatar.com/avatar/016dd2cbccb8ba3bd03c71026d042f64?s=96&d=mm&r=g",
    "billing": {
      "first_name": "Tales2",
      "last_name": "Silva",
      "company": "",
      "address_1": "Estrada de Itapecerica",
      "address_2": "apto 34",
      "city": "São Paulo",
      "state": "SP",
      "postcode": "05835-003",
      "country": "BR",
      "email": "webtailss@gmail.com",
      "phone": "(11) 97012-3941",
      "neighborhood": "Vila das Belezas",
      "number": "1187"
    },
    "billing_cnpj": "",
    "billing_cpf": "374.593.318-48",
    "billing_persontype": "1",
    "email": "webtailss@gmail.com",
    "first_name": "Tales2",
    "id": 15,
    "indicadores": {
      "next_payment_date": null,
      "is_manual": null,
      "billing_period": null,
      "schedule_start": null,
      "schedule_end": null,
      "schedule_cancelled": 0,
      "nivel_assinatura": "user_lvl_04",
      "sku_01": "true", //SpeedFlow Elite
      "sku_02": "false", //SpeedFlow Light
      "sku_03": "false", //MacroFlow
      "sku_04": "false", //FLuxoV6
      "sku_05": "false", // Alvo R3
      "sku_06": "false", //Quantum LT
      "sku_07": "false" //Pacote 4 Indicadores
    },
    "mentoria": "true",
    "last_name": "Silva",
    "role": "subscriber",
    "username": "tales.silva"
  };

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_CUSTOM_API_URL}/v1/user-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const userData = response.data;
      setUser(userData);
      console.log('Usuário definido no contexto:', userData);
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error);
      setUser(null);
      localStorage.removeItem('token');
    } finally {
      setLoading(false);
    }
  };

  const initializeUser = () => {
    if (useMockData) {
      // Se estiver usando os dados mocados, definir o usuário diretamente
      setUser(mockUser);
      setLoading(false);
    } else {
      // Usar dados reais da API
      const token = localStorage.getItem('token');
      if (token) {
        fetchUserData(token);
      } else {
        setUser(null);
        setLoading(false);
      }
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
  };

  useEffect(() => {
    initializeUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, logout, loading }}>
      {children}
    </UserContext.Provider>
  );
};
