import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import Icons from '../../Components/Menu/Icons';
import { Helmet } from 'react-helmet';

const Downloads = () => {

    // Em vez de usar useContext, usamos o objeto mocado
    const { user } = useContext(UserContext);

    useEffect(() => {
        console.log('User data:', user);
    }, [user]);

    if (!user || !user.indicadores) {
        return <p>Carregando...</p>;
    }

    // Regra 1: Se for 'customer', mostrar que ele não tem assinatura ativa
    if (user.role === 'customer') {
        return (
            <div className="p-6 bg-white rounded">
                <Helmet>
                    <title>Meus Downloads - Fluxo MTA</title>
                    <meta name="description" content="This is the home page" />
                </Helmet>
                <div className='flex justify-between items-center mb-4'>
                    <h2 className="flex items-center text-2xl font-bold">
                        Atenção
                    </h2>
                </div>
                <p>Você não possui uma assinatura ativa no momento. Por favor, adquira uma assinatura para acessar os downloads.</p>
            </div>
        );
    }

    const productMapping = {
        sku_00: { name: 'Indicador ZD', file: '/Downloads/ZD_Outubro.zip' },
        sku_03: { name: 'MacroFlow' },
        sku_04: { name: 'Indicador Fluxo V6', file: '/Downloads/Fluxo_V6_Outubro.zip' },
        sku_05: { name: 'Indicador ALVO R3', file: '/Downloads/Alvo_R3_Outubro.zip' },
        sku_06: { name: 'Indicador Quantum LT', file: '/Downloads/Quantum_LT_Outubro.zip' },
        sku_07: { name: 'Pacote de 4 Indicadores', skus: ['sku_04', 'sku_05', 'sku_06', 'sku_00'] },
        community: { name: 'Comunidade Telegram', link: 'https://t.me/+miwgD0N2MaM1NWRh' },
    };

    const activeProducts = [];

    // Regra 4: Se o usuário for 'administrator', libera todos os produtos
    if (user.role === 'administrator') {
        // Adiciona todos os SKUs
        Object.keys(productMapping).forEach((sku) => {
            activeProducts.push(sku);
        });
    }

    // Regra 3: Se o usuário for 'subscriber', aplicar a lógica de mapeamento de produtos
    else if (user.role === 'subscriber') {
        // Usuário tem acesso automático ao Indicador ZD (sku_00)
        activeProducts.push('sku_00');

        // Se o usuário tem acesso ao pacote (SKU_07), adiciona os SKUs relevantes
        if (user.indicadores.sku_07 === "true") {
            productMapping.sku_07.skus.forEach(sku => {
                if (!activeProducts.includes(sku)) {
                    activeProducts.push(sku);
                }
            });
        }

        // Se o usuário tem acesso ao SKU_01, adiciona os SKUs relevantes e community
        if (user.indicadores.sku_01 === "true") {
            ['sku_03', 'sku_04', 'sku_05', 'sku_06'].forEach(sku => {
                if (!activeProducts.includes(sku)) {
                    activeProducts.push(sku);
                }
            });
            activeProducts.push('community');
        }

        // Se o usuário tem acesso ao SKU_02, adiciona os SKUs relevantes, sem community
        if (user.indicadores.sku_02 === "true") {
            ['sku_03', 'sku_04', 'sku_06'].forEach(sku => {
                if (!activeProducts.includes(sku)) {
                    activeProducts.push(sku);
                }
            });
        }

        // Verifica individualmente outros produtos baseados nos indicadores do usuário
        Object.keys(user.indicadores).forEach((sku) => {
            if (user.indicadores[sku] === "true" && sku !== 'sku_07' && !activeProducts.includes(sku)) {
                activeProducts.push(sku);
            }
        });
    }

    const handleDownload = (file, name) => {
        const link = document.createElement('a');
        link.href = file;
        const cpf = user.billing_cpf.replace(/[^\d]/g, '');
        link.download = `${name}_${cpf}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Helmet>
                <title>Meus Downloads - Fluxo MTA</title>
                <meta name="description" content="This is the home page" />
            </Helmet>

            <div className="p-6 bg-white rounded">
                <div className='flex justify-between items-center mb-4'>
                    <h2 className="flex items-center text-2xl font-bold">
                        <Icons.Downloads className="w-6 mr-2" />
                        Downloads Disponíveis
                    </h2>
                </div>

                {activeProducts.length > 0 && (
                    <div>
                        {activeProducts.map((sku) => {
                            const product = productMapping[sku];
                            if (!product) return null;

                            return sku === 'community' ? (
                                <div key={sku} className="flex items-center p-4 bg-[#2AABEE] text-white rounded mb-2">
                                    <a
                                        href={product.link}
                                        className="text-white hover:underline flex"
                                    >
                                        <Icons.DownloadIc className="w-6 mr-2" /> Acessar {product.name}
                                    </a>
                                </div>
                            ) : product.file ? (
                                <div key={sku} className="flex items-center p-4 bg-gray-100 rounded mb-2">
                                    <button
                                        onClick={() => handleDownload(product.file, product.name)}
                                        className="text-blue-600 hover:underline flex"
                                    >
                                        <Icons.DownloadIc className="w-6 mr-2" /> Download {product.name}
                                    </button>
                                </div>
                            ) : (
                                <div key={sku} className="p-4 bg-gray-100 rounded text-blue-600 mb-2">
                                    Acesso ao <strong>{product.name}</strong> está ativo. Aproveite todos os benefícios!
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className='py-4 text-primary-400 text-sm leading-7'>
                    <p>Termos e Condições de Uso <br /> <strong> Por favor, leia com atenção!</strong> 💡</p>
                    <p>Conforme o Artigo 38 da Lei nº 8078, devido à natureza de consumo imediato deste infoproduto — entregue 100% para download antes do prazo de 7 dias de garantia — é importante que você entenda as seguintes condições:</p>
                    <p>Ao realizar o download do material, você renuncia automaticamente ao direito de solicitar reembolso, uma vez que o produto seja consumível e não possa ser devolvido após o acesso.</p>
                    <p>Desta forma, ao clicar no link de download, o produto é considerado adquirido e não poderá ser devolvido.</p>
                    <p><strong>Portanto, ao acessar o material, esteja plenamente consciente e de acordo com todas essas condições.</strong></p>
                </div>
            </div>
        </>
    );
};

export default Downloads;
